<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="filteredCountedProducts && countData">
      <trac-back-button>{{ countData.from }}</trac-back-button>
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-2xl capitalize">
            {{ countData.name }}
          </h3>
        </div>
        <div class="flex-col">
          <trac-button @button-clicked="saveForLater" class="uppercase"
            >save for later</trac-button
          >
        </div>
      </div>

      <div class="flex flex-row h-full gap-5">
        <div class="px-8 py-8 w-6/12 bg-white rounded-lg h-auto big-shadow">
          <div>
            <!-- <button
              @click="scannerCtrl = !scannerCtrl"
              class="font-semibold text-sm text-primaryBlue"
            >
              {{ scannerCtrl ? "Hide Scanner" : "Show Scanner" }}
            </button> -->
          </div>
          <div class="h-128 flex flex-col items-center">
            <!-- v-if="!QRReaderActive" -->
            <span class="text-sm text-primaryGray mx-auto">
              <trac-toggle
                class="mb-2"
                :active="scannerCtrl"
                @newval="scannerCtrl = !$event"
              >
                {{ scannerCtrl ? "Open" : "Close" }}
              </trac-toggle>
            </span>

            <StreamBarcodeReader
              class="mt-4"
              v-if="scannerCtrl"
              v-show="QRReaderActive"
              @decode="onDecode"
              @loaded="onLoaded"
            ></StreamBarcodeReader>
            <div class="flex justify-center items-center w-full" v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="120"
                class="mt-32"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#909090af"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M2 2l19.8 19.8M15 15.7V17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h.3m5.4 0H13a2 2 0 0 1 2 2v3.3l1 1L22 7v10"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="px-8 py-8 w-6/12 bg-white rounded-lg h-full overflow-hidden big-shadow"
        >
          <div class="">
            <p class="text-xs text-gray-500 text-right">
              <span class="text-red-600">*</span>
              Only products that are stock managed are displayed.
            </p>
          </div>
          <div class="bg-white border mt-5 rounded-lg overflow-auto">
            <table class="w-full rounded-lg table-auto overflow-auto">
              <thead class="bg-blue-100">
                <tr class="text-center">
                  <th class="text-left p-4 text-sm font-semibold uppercase">
                    products
                  </th>
                  <th class="text-left p-4 text-sm font-semibold uppercase text-right">
                    counted
                  </th>
                  <!-- <th class="text-left p-4 text-sm font-semibold uppercase">
                    expected
                  </th> -->
                </tr>
              </thead>
              <tbody
                class="overflow-auto h-60"
                v-if="productsToCount.length > 0"
              >
                <tr
                  v-for="(product, index) in productsToCount"
                  :key="index"
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                >
                  <td class="p-4 text-xs font-medium">
                    {{ product.title || product.name }}
                  </td>
                  <td class="p-4 text-xs font-medium">
                    <div
                      class="flex flex-row gap-3 justify-end items-center"
                    >
                      <img
                        class="cursor-pointer"
                        @click="handleQty('increase', product)"
                        src="../../../assets/svg/add.svg"
                      />
                      <input
                        class="w-2/12 p-2 text-center"
                        type="number"
                        v-model.number="product.counted"
                      />
                      <img
                        class="cursor-pointer"
                        @click="handleQty('decrease', product)"
                        src="../../../assets/svg/minus.svg"
                      />
                    </div>
                  </td>
                  <!-- <td class="p-4 text-xs font-medium">
                    {{ product.expected }}
                  </td> -->
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3">
                    <trac-center-data>
                      <div class="h-40 flex items-center text-xs text-gray-600">
                        No product available for count.
                      </div>
                    </trac-center-data>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-16">
            <trac-button
              :disabled="productsToCount.length === 0"
              @button-clicked="sendCount"
              class="uppercase w-full"
              >Submit Count</trac-button
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { GENERATE_RANDOM_ID } from "../../../browser-db-config/utilityMethods";
import { eventBus } from "./../../../main";
import products from '../../../store/modules/products';

export default {
  name: "SubmitCount",
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      isLoading: false,
      countData: null,
      selectedCategoryNames: null,
      products: null,
      filteredCountedProducts: null,
      QRReaderActive: false,
      scannerCtrl: true,
    };
  },
  async created() {
    this.isLoading = true;
    this.countData = GET_LOCAL_DB_DATA("stock-count-data");

    if (!this.countData) {
      this.$router.back();
    }
    if (this.countData) {
      this.countData.counted_products = this.countData.saved_counted_details
        ? this.countData.saved_counted_details.Products
        : [];
      this.selectedCategoryNames = this.countData.selected_categories.map(
        (category) => category.name.trim().toLowerCase()
      );
    }

    await this.fetchAllProducts();
    this.isLoading = false;
  },
  computed: {
    productsToCount() {
      const productsToCountData = [];
      this.filteredCountedProducts.forEach((product) => {

        if (
          product.variation &&
          this.selectedCategoryNames.includes(product.category.toLowerCase())
        ) {
          productsToCountData.push(...product.varieties);
        } else if (this.selectedCategoryNames.includes(product.category.toLowerCase())) {
          productsToCountData.push(product);
        }
      });

      return productsToCountData;
      // this.selectedCategoryNames;
      // ? this.products.filter((product) =>
      //     product.variation ? product.varieties.filter(variant => this.selectedCategoryNames.includes) : this.selectedCategoryNames.includes(product.category)
      //   )
      // : [];
    },
  },
  methods: {
    onLoaded(result) {
      this.QRReaderActive = true;
    },
    runCount() {
      this.QRReaderActive = false;
    },
    onDecode(result) {
      // (result);
    },
    saveForLater() {
      let savedCounts = GET_LOCAL_DB_DATA("save_for_later_prod") || [];

      if (this.countData.saved_counted_details) {
        savedCounts = savedCounts.filter(
          (count) =>
            count.sub_id !== this.countData.saved_counted_details.sub_id
        );
      }

      savedCounts.push({
        countData: this.countData,
        sub_id: GENERATE_RANDOM_ID(),
        name: this.countData.name,
        date: new Date().toISOString(),
        paused: true,
        location: this.countData.selectedStore.name,
        Products: this.productsToCount.map((prod) => {
          return {
            ...prod,
            _id: prod._id,
            name: prod.title,
            counted: prod.counted,
            reason: prod.reason || "",
          };
        }),
      });

      SAVE_LOCAL_DB_DATA("save_for_later_prod", savedCounts);
      DELETE_LOCAL_DB_DATA("submited-stock-count");
      DELETE_LOCAL_DB_DATA("stock-count-data");
      this.$router.push({ name: "StockCount" });
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.products = res.items || [];
        if (this.countData.from === "Count Details") {
          const countedProducts = this.countData.counted_products || [];
          this.filteredCountedProducts = countedProducts.filter(
            (p) => p.manage_stock
          );
          return;
        }
        this.products = this.products.filter((p) => p.manage_stock);

        this.products = this.products.map((product) => {
          if (product.variation) {
            return {
              ...product,
              varieties: product.varieties.map((variant) => {
                let item;
                this.countData.counted_products.forEach((p) => {
                  if (p._id === variant._id) {
                    item = {
                      ...variant,
                      counted: p.counted,
                      category: product.category,
                      parent_product_id: product._id,
                      is_variant: product.variation,
                      variant_id: variant._id,
                      p_id: product._id,
                    };
                  } else if (p._id !== variant._id && !item) {
                    item = {
                      ...variant,
                      counted: 0,
                      category: product.category,
                      parent_product_id: product._id,
                      is_variant: product.variation,
                      variant_id: variant._id,
                      p_id: product._id,
                    };
                  }
                });
                return (
                  item || {
                    ...variant,
                    counted: 0,
                    category: product.category,
                    parent_product_id: product._id,
                    is_variant: product.variation,
                    variant_id: variant._id,
                    p_id: product._id,
                  }
                );
              }),
            };
          } else {
            let item;
            this.countData.counted_products.forEach((p) => {
              if (p._id === product._id) {
                item = {
                  ...product,
                  counted: p.counted,
                  parent_product_id: product._id,
                  is_variant: product.variation,
                };
              } else if (p._id !== product._id && !item) {
                item = {
                  ...product,
                  counted: 0,
                  parent_product_id: product._id,
                  is_variant: product.variation,
                };
              }
            });
            return (
              item || {
                ...product,
                counted: 0,
                parent_product_id: product._id,
                is_variant: product.variation,
              }
            );
          }
        });

        this.filteredCountedProducts = this.products;

      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
    getProductSpecificStore(prod) {
      return (
        prod.store_stock.find((ss) => ss.store_id === this.countData.selectedStore._id) || {}
      );
    },
    handleQty(type, product) {
      this.filteredCountedProducts.forEach((prod) => {
        if (prod.variation) {
          prod.varieties.forEach((variant) => {
            if (type === "increase" && variant._id === product._id) {
              variant.counted += 1;
            } else if (type === "decrease" && variant._id === product._id) {
              if (variant.counted > 1) {
                variant.counted -= 1;
              }
            }
          });
        } else {
          if (type === "increase" && prod._id === product._id) {
            prod.counted += 1;
          } else if (type === "decrease" && prod._id === product._id) {
            if (prod.counted > 1) {
              prod.counted -= 1;
            }
          }
        }
      });
    },
    async sendCount() {
      if (this.countData.saved_counted_details) {
        SAVE_LOCAL_DB_DATA(
          "submited-saved-stock-count-details",
          this.countData.saved_counted_details
        );
      }
      
      SAVE_LOCAL_DB_DATA("submited-stock-count", this.productsToCount);
      this.$router.push({ name: "AcceptCount" });
    },
  },
};
</script>

<style scoped></style>
